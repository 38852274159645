export const LandingVariants = {
    modern: {
        title: 'Modern style',
        description: 'Drive more customers to engage with your intended CTA via a clean non-distracting design',
        isEnabled: (brandSettings?: Partial<BrandSettings>) => {
            return brandSettings?.landingPage?.variant === 'modern' || !brandSettings?.landingPage?.variant;
        },
    },
    'linkTree': {
        title: 'Linktree style',
        description: 'Use full width large buttons',
        isEnabled: (brandSettings?: Partial<BrandSettings>) => {
            return brandSettings?.landingPage?.variant === 'linkTree';
        },
    }
} as const

export type LandingVariant = keyof typeof LandingVariants;

export const IdentifiationMethods = ["age-gate", "multiple-choice"] as const;
export type IdentifiationMethod = typeof IdentifiationMethods[number];

export const LandingPageButtonLinks = {
    shopOnline: {
        title: 'Shop Online',
        description: 'Link to your online store',
    },
    directions: {
        title: 'Directions',
        description: 'Link to your location on a map',
    },
    callUs: {
        title: 'Call Us',
        description: 'Link to a phone number',
    },
    memberWallet: {
        title: 'Member Wallet',
        description: 'Link to a member wallet page',
    }
} as const

export type LandingPageButtonLinkType = keyof typeof LandingPageButtonLinks;


export type LandingPageSettings = {
    variant?: LandingVariant;
    captchaMethod?: IdentifiationMethod;
    buttonsMap?: Record<LandingPageButtonLinkType, LandingPageButtonLink>;
    style?: BrandStyle;
}


export type LandingPageButtonLink = {
    text?: string;
    url?: string;
    enabled?: boolean;
}


export type CaptchaPageSettings = {
    methods?: Record<string, CaptchaPageMethod>;
    style?: BrandStyle;
}

export type CaptchaPageMethods = {
    pin?: CaptchaPageMethod;
    dob?: CaptchaPageMethod;
    phone?: CaptchaPageMethod;
}


export type CaptchaPageMethod = {
    header?: TextContainer;
    button?: ButtonStyle;
    enabled?: boolean;
}


export interface BrandSettings {
    style: BrandStyle;
    landingPage: LandingPageSettings;
    captchaPage: CaptchaPageSettings;
}

export interface BrandStyle extends BrandColorPallet, TextStyles {
    avatarLogo?: string;
    fullLogo?: string;
    coverBackground?: string;
    input?: InputStyle;
    buttonPrimary?: ButtonStyle;
    buttonSecondary?: ButtonStyle;
    buttonSpecial?: ButtonStyle;
}

export interface BrandColorPallet {
    brandColor?: string;
    secondaryColor?: string;
    specialColor?: string;
    linkColor?: string;
    successColor?: string;
    warningColor?: string;
    dangerColor?: string;
    textBrand?: string;
    textPrimary?: string;
    textSecondary?: string;
    surfacePrimary?: string;
    surfaceSecondary?: string;
    borderPrimary?: string;
}

export interface InputStyle extends TextContainer {
    placeholder?: string;
    disabled?: Partial<TextContainer>;
}

export interface ButtonStyle extends TextContainer {
    height?: string;
    width?: string;
    disabled?: Partial<TextContainer>;
    selected?: Partial<TextContainer>;
}

export interface TextContainer extends ContainerStyles, TextStyles {
    textDefault?: string;
    textAlt?: string;
    textSuccess?: string;
    textLoading?: string;
}

export interface ContainerStyles {
    display?: string;
    flexDir?: string;
    justifyContent?: string;
    alignItems?: string;
    textAlign?: string;
    margin?: PaddingType;
    padding?: PaddingType;
    borderRadius?: BorderRadiusType;
    bg?: string;
    bgHover?: string;
    borderColor?: string;
    borderColorHover?: string;
}

export interface TextStyles {
    color?: string;
    colorHover?: string;
    fontWeight?: string;
    fontSize?: number;
    fontFamily?: string;
}

export interface PaddingType {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

export interface BorderRadiusType {
    topLeft: number;
    topRight: number;
    bottomLeft: number;
    bottomRight: number;
}
